import { ChangeDetectorRef, Component, HostListener, Injector, Input, OnInit, SimpleChanges } from "@angular/core";
import * as Highcharts from "@node_modules/highcharts";
import HC_exporting from "@node_modules/highcharts/modules/exporting";
import { DashboardItemWidgetType } from "@app/shared/common/widget/widget-consts";
import { SelectionType } from "@app/shared/common/highcharts/toolbox.component";
import { PointGroup } from "@app/shared/common/highcharts/highcharts.component";
import * as _ from "lodash";
import { Point } from "@node_modules/highcharts";
import { ReportDashboardServiceProxy } from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { DataWidgetConfigContent } from "@app/shared/models/DataWidgetConfigDto";

let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
HC_exporting(Highcharts);

@Component({
    selector: "app-scatter-plot-chart",
    templateUrl: "./scatter-plot-chart.component.html",
    styleUrls: ["./scatter-plot-chart.component.css"]
})
export class ScatterPlotChartComponent extends AppComponentBase implements OnInit {
    @Input() divId: string;
    @Input() chartName: string;
    @Input() data = new Array();
    @Input() lineData = new Array();
    @Input() seriesData = new Array();

    @Input() xColumnName: string;
    @Input() yColumnName: string;
    @Input() seriesName: string;
    @Input() unitType: string;
    @Input() yColumnNames = new Array();
    @Input() completedEventId: string;
    @Input() dataLabelEnabled: boolean = false;
    @Input() xColumnunitType: string = "m/s";
    @Input() selectionType: SelectionType;
    higcharts = new Map<string, Highcharts.Chart>();
    private isZoomed: boolean;

    public innerWidth: number;
    private chart: Highcharts.Chart;
    highchart: any;
    isSetup: boolean = false;
    private selectionSvg: Highcharts.SVGElement[] = [];
    public selectedPoints: Point[] = [];
    deletedData = [];

    // copySeriesData = [];
    // copyData = [];

    constructor(
        injector: Injector,
        private _reportDashboardServiceProxy: ReportDashboardServiceProxy,
        private _changeDetector: ChangeDetectorRef
    ) {
        super(injector);
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.seriesData) {
            this.isSetup = false;
            this.initChart();
        }
        if (changes.data) {
            this.isSetup = false;
            this.initChart();
        }
    }

    getChartOptions() {
        return {
            chart: {
                type: "scatter",
                zoomType: "xy",
                events: {
                    selection: (event) => {

                        if (event["resetSelection"]) {
                            if (this.isZoomed) {
                                this.isZoomed = false;
                                // this.onResetSelect.emit();
                            }

                            return true;
                        } else {
                            const x1 = event.xAxis[0].min;
                            const x2 = event.xAxis[0].max;
                            const y1 = event.yAxis[0].min;
                            const y2 = event.yAxis[0].max;
                            if (this.selectionType === SelectionType.Zoom) {
                                this.isZoomed = true;
                                if (event.xAxis) {
                                    // Zoom gerçekleşti
                                    console.log("Zoom yapıldı");
                                    this.afterEventSendCompletedInfo(true);
                                } else {
                                    // Zoom yapılmadı veya geri alındı
                                    console.log("Zoom geri alındı veya yapılmadı");
                                    this.afterEventSendCompletedInfo(true);
                                }
                                return true;
                            } else if (this.selectionType === SelectionType.Select) {
                                let chartData = this.getDataBySelection(x1, x2, y1, y2);
                                this.drawSelectionByDates(chartData);
                                return false;
                            }
                        }
                    }
                }
            },
            boost: {
                useGPUTranslations: true,
                // Chart-level boost when there are more than 5 series in the chart
                seriesThreshold: 5
            },

            title: {
                text: ""
            },
            legend: {
                enabled: true
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 2.5,
                        symbol: "circle",
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: "rgb(100,100,100)"
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    jitter: {
                        x: 0.005
                    },
                    dataLabels: {
                        enabled: this.dataLabelEnabled,
                        formatter: function() {
                            let s = this.key;
                            return s;
                        },
                        y: 25
                    }
                },
                series: {
                    events: {
                        legendItemClick: () => {
                            this.afterEventSendCompletedInfo(true);
                        }
                    }
                }
            },
            tooltip: {
                pointFormat: this.xColumnName + ": {point.x} " + this.xColumnunitType + " <br/> " + this.yColumnName + ": {point.y} " + this.unitType
            },
            credits: {
                enabled: false
            },
            lang: {
                noData: "",
                loading: "<img src=\"/assets/common/images/loading.gif\"> <br/>Loading..."
            }
        };
    }


    selectAllSeries() {
        this.chart.series.forEach((series) => {
            if (!series.visible) {
                series.show();
            }
        });
    }

    ngOnInit() {
        this.innerWidth = window.innerWidth;
        this.deletedData = [];
        this.changeSelection();
        this.removePoints();
        this.cancelSelection();
    }

    initChart() {
        let chart = document.querySelector("#" + this.divId + " #" + this.chartName);

        // @ts-ignore
        let highchart = Highcharts.chart(
            // @ts-ignore
            chart,
            Highcharts.merge(this.getChartOptions(), {
                xAxis: {
                    title: {
                        text: this.xColumnName
                    },
                    endOnTick: true,
                    showLastLabel: true
                },
                yAxis: {
                    title: {
                        text: this.yColumnName
                    }
                },
                series: [
                    {
                        name: this.seriesName,
                        id: this.seriesName,
                        marker: {
                            symbol: "circle"
                        },
                        data: []
                    }
                ]
            })
        );

        if (this.seriesData.length > 0) {
            for (var s in highchart.series) {
                highchart.get(highchart.series[s].options.id).remove(false);
            }
        }

        const intervalId = setInterval(() => {
            if (highchart) {
                if (this.data.length > 0) {
                    if (highchart.series) {
                        if (highchart.series.length > 0) {
                            highchart.series[0].setData(this.data);
                            this.sendCompletedInfo(true);
                        }
                    }

                }
                else {
                    this.sendCompletedInfo(false);
                }

                if (this.seriesData.length > 0) {
                    if (!this.isSetup) {
                        for (let s in highchart.series) {
                            highchart.get(highchart.series[s].options.id).remove(false);
                        }
                        if (highchart.series && highchart.series.length == 0) {
                            this.seriesData.map((item) => {
                                highchart.addSeries(item, false);
                            });

                            this.chart = highchart;
                            highchart.redraw(true);

                            this.sendCompletedInfo(true);
                        }
                    }
                }
                else {
                    this.sendCompletedInfo(false);
                }

                this.highchart = highchart;

                // Koşulunuz: this.data veya this.seriesData'nın uzunluğu 0 ise, setInterval'i durdur
                if (this.data.length > 0 || this.seriesData.length > 0) {
                    clearInterval(intervalId);
                }
            }
        }, 1000);
    }

    sendCompletedInfo(isCompleted: boolean) {
        setTimeout(() => {
            abp.event.trigger(this.completedEventId, {
                isCompleted: isCompleted,
                highChart: this.highchart,
                type: DashboardItemWidgetType.chart
            });
            this.isSetup = true;
            this.higcharts.set(this.completedEventId, this.highchart);
        }, 100);
    }

    afterEventSendCompletedInfo(isCompleted: boolean) {
        const highchartObject = this.higcharts.get(this.completedEventId);

        abp.event.trigger(this.completedEventId, {
            isCompleted: isCompleted,
            highChart: highchartObject,
            type: DashboardItemWidgetType.chart
        });
        this.isSetup = true;
    }

    unSelectAllSeries() {
        this.chart.series.forEach((series) => {
            series.hide();
        });
    }

    private getDataBySelection(x1: number, x2: number, y1: number, y2: number): Point[] {
        x1 = Number(x1.toFixed(2));
        x2 = Number(x2.toFixed(2));
        y1 = Number(y1.toFixed(2));
        y2 = Number(y2.toFixed(2));

        let points = [];
        for (let i = 0; i < this.highchart.series.length; i++) {
            let seriesPoints = this.highchart.series[i].userOptions["data"].filter(
                (d: any) =>
                    (!x1 || d[0] >= x1) && (!x2 || d[0] <= x2)
                    &&
                    d[1] != null && (!y1 || d[1] >= y1) && (!y2 || d[1] <= y2)
            );
            if (seriesPoints.length > 0) {
                let indexList = [];
                for (let j = 0; j < seriesPoints.length; j++) {
                    let indexItem = this.highchart.series[i].userOptions["data"].indexOf(seriesPoints[j]);
                    indexList.push(indexItem);
                }
                let items = this.highchart.series[i].points.filter((x) => indexList.indexOf(x["i"]) > -1 || indexList.indexOf(x["index"]) > -1);
                points = points.concat(items);
            }
        }
        return points;
    }

    public drawSelectionByDates(selectedPoints: Point[]) {
        if (_.isEmpty(selectedPoints)) return;
        this.deselectAllPoints();
        this.selectedPoints = selectedPoints;
        this.selectionSvg = this.renderSvgPointsByDate(selectedPoints, "red", 6);
    }

    deselectAllPoints() {
        for (let svg of this.selectionSvg) {
            svg.destroy();
        }
        this.selectionSvg = [];
        this.selectedPoints = [];
    }

    private renderSvgPointsByDate(points: Point[], color: string, zIndex: number): Highcharts.SVGElement[] {
        let addedSvgElements: Highcharts.SVGElement[] = [];
        for (let point of points) {
            const svgElement = this.highchart.renderer
                .circle(point.plotX + this.highchart.plotLeft, point.plotY + this.highchart.plotTop, 5)
                .attr({
                    zIndex: zIndex,
                    fill: color
                })
                .add();
            addedSvgElements.push(svgElement);
        }
        return addedSvgElements;
    }


    removePoints() {
        this.subscribeToEvent(this.divId + "-scatter-remove", (response) => {
            for (let selection of this.selectedPoints) {
                if (this.seriesData.length > 0) {
                    for (const item of this.seriesData) {
                        // item.data'yı sondan başa doğru iterate ediyoruz
                        for (let i = item.data.length - 1; i >= 0; i--) {
                            let control = item.data[i];
                            if (control && control[0] == selection.x) {
                                this.deletedData.push(control);
                                item.data.splice(i, 1);
                            }
                        }
                    }
                }

                if (this.data.length > 0) {
                    // this.data'yı sondan başa doğru iterate ediyoruz
                    for (let i = this.data.length - 1; i >= 0; i--) {
                        let control = this.data[i];
                        if (control && control[0] == selection.x) {
                            this.deletedData.push(control);
                            this.data.splice(i, 1);
                        }
                    }
                }
            }
            setTimeout(() => {
                this.isSetup = false;
                this.initChart();
                abp.event.trigger(this.divId + "-scatter-chart-update", this.deletedData);
            }, 1000);
        });
    }

    changeSelection() {
        this.subscribeToEvent(this.divId + "-scatter-change-selection", (response) => {
            this.selectionType = response;
            this.isSetup = false;
            this.deselectAllPoints();
            this.selectedPoints = [];
            this.selectionSvg = [];

        });
    }

    cancelSelection() {
        this.subscribeToEvent(this.divId + "-cancel-selection", (response) => {
            this.selectionType = response;
            this.isSetup = false;
            this.deselectAllPoints();
            this.selectedPoints = [];
            this.selectionSvg = [];
            // this.initChart();
            abp.event.trigger(this.divId + "-scatter-chart-refresh", this.deletedData);
        });
    }


}
