<div [className]="getAssetButtonClassName('button')" dropdown #dropdown="bs-dropdown" container="body"
     [isDisabled]="isDisabled"
     (onHidden)="onDropdownHidden()">
    <div [className]="getAssetIcon('start-icon-3')"></div>
    <div [className]="getAssetLabelClassName('label')" dropdownToggle>
        {{ getLabels() }}
    </div>
    <img class="icon" [src]="getAssetSrc('assets/common/images/report/widget/end-icon-1-disabled.svg')"
         alt="end icon">
    <ul class="dropdown-menu" *dropdownMenu role="menu" aria-labelledby="button-triggers-manual">
        <div class="asset-conf-filter-selected">
            <div class="content-1">
                <div class="list">
                    <article class="menu-item" *ngFor="let item of this.powerPlantUnits">
                        <div class="content">
                            <div
                                class="label publicsans-normal-charade-14px">
                                {{ item.value }}
                            </div>
                        </div>
                        <div class="checkbox">
                            <div class="checkbox-1">
                                <div class="icon-container"
                                     (click)="onPowerPlantUnitSelect(item.id,$event)">
                                    <img
                                        href="javascript:"
                                        class="iconsic_checkbox_o"
                                        [src]="item.selected
                                            ? 'assets/common/images/asset-configuration/icons-ic-checkbox-on.svg'
                                            : 'assets/common/images/asset-configuration/icons-ic-checkbox-off.svg'"
                                        alt="icons/ic_checkbox_off">
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
                <div class="foot">
                    <div
                        [className]="isAllFiltersSelected() ? 'menu-item-1':'menu-item'">
                        <div class="checkbox">
                            <div class="checkbox-1">
                                <div class="icon-container"
                                     (click)="toggleOptionAllSelect()">
                                    <img class="iconsic_checkbox_o"
                                         [src]="isAllFiltersSelected()
                                            ? 'assets/common/images/asset-configuration/icons-ic-checkbox-on.svg'
                                            : 'assets/common/images/asset-configuration/icons-ic-checkbox-off.svg'"
                                         alt=" icons/ic_checkbox_on">
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <div
                                class="label publicsans-normal-charade-14px">
                                All Responses
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ul>
</div>
