import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { PowerPlantDto, WidgetInfoDto } from "@shared/service-proxies/service-proxies";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "app-power-plant-selection",
    templateUrl: "./power-plant-selection.component.html",
    styleUrls: ["./power-plant-selection.component.css"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: PowerPlantSelectionComponent,
            multi: true,
        },
    ],
})
export class PowerPlantSelectionComponent implements ControlValueAccessor, OnInit, OnChanges {
    @Input() activated = true;
    @Input() powerPlants: PowerPlantDto[] = [];
    @Input() powerPlantIdFilter: number = -1;
    @Input() selectedWidget: WidgetInfoDto;

    value: number;
    isDisabled: boolean;

    private onChange: any;
    private onTouched: any;

    ngOnInit() {
        this.filterPowerPlants();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.filterPowerPlants();
    }

    private filterPowerPlants() {
        if (this.selectedWidget && this.selectedWidget.powerPlantType) {
            const powerPlantTypes = this.selectedWidget.powerPlantType; // Number[] türündeki tipler
            this.powerPlants = this.powerPlants.filter(
                (powerPlant) =>
                    powerPlantTypes.length === 1
                        ? powerPlant.type.toString() === powerPlantTypes[0].toString() // Tek tip için
                        : powerPlantTypes.some((type) => type.toString() === powerPlant.type.toString()) // Birden fazla tip için
            );
        }
    }

    getAssetButtonClassName(defaultClass: string): string {
        return this.activated ? "button-active" : defaultClass;
    }

    getAssetLabelClassName(defaultClass: string) {
        return this.activated ? "label-active" : defaultClass;
    }

    getAssetSrc(defaultUrl: string) {
        return this.activated ? "assets/common/images/report/widget/end-icon-1.svg" : defaultUrl;
    }

    getAssetIcon(startIcon: string) {
        return this.activated ? startIcon + "-active" : startIcon;
    }

    onPowerPlantSelect(event: Event) {
        this.onTouched();
        this.onChange(+(event.target as HTMLSelectElement).value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(value: any): void {
        this.value = value;
    }
}
