import { Injectable, Injector } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    ComponentDto,
    IotDataResponse,
    IotDataValue,
    PagedResultDtoOfGetPowerPlantForViewDto,
    PowerPlantDto,
    PowerPlantType,
    PowerPlantUnitDto,
    SchemaSnapshotStatus,
    WindUnitModelStatusDto,
    WindUnitStatus,
    WindUnitStatusCodesDto,
} from "@shared/service-proxies/service-proxies";
import * as _ from "lodash";
import { BaseComponentConsts } from "@app/main/report-trials/WidgetConsts";
import { LocalStorageService } from "@shared/utils/local-storage.service";
import {
    MapConstants,
    UnitStatusClasses,
    UnitStatusCount,
    UnitStatusCountStack,
    UnitStatusData,
    UnitStatusText,
    UnitStatusTextClasses,
    UnitStatusTextContentClasses,
} from "@app/main/map/map.const";
import { HydroUnitStatus, SolarUnitStatus } from "@app/main/map/map.model";

@Injectable()
export class MapService extends AppComponentBase {
    readonly WindUnitStatus = WindUnitStatus;
    readonly Mw = " MW";
    readonly ms = " m/s";
    readonly UnitMinWidthPx = 35;
    statusCodes: SchemaSnapshotStatus[];
    totalTurbineCountInProductionByPowerPlant = new Map<number, number>();
    powerPlants: PowerPlantDto[];
    powerPlantUnits: PowerPlantUnitDto[];
    allWindUnitStatus: WindUnitStatusCodesDto[];
    powerPlantsForPage: PagedResultDtoOfGetPowerPlantForViewDto;

    windPowerPlantStatusState = new Map<
        number,
        {
            warningExist: boolean;
            maintenanceExist: boolean;
            outOfCommunicationExist: boolean;
        }
    >();
    solarPowerPlantStatusState = new Map<
        number,
        {
            stopExist: boolean;
            standStillExist: boolean;
        }
    >();
    hydroPowerPlantStatusState = new Map<
        number,
        {
            warningExist: boolean;
            maintenanceExist: boolean;
            standStillExist: boolean;
            outOfCommunicationExist: boolean;
        }
    >();

    constructor(injector: Injector, private _localStorageService: LocalStorageService) {
        super(injector);
    }

    getProductionValueByPowerPlantType(
        _powerPlant: PowerPlantDto,
        data: IotDataResponse,
        filteredUnitComponents: ComponentDto[],
        tenancyName: string
    ): number {
        if (filteredUnitComponents.length === 0) {
            return 0;
        }

        const filterByOpcItemId = (component: ComponentDto) => {
            let uuId = component.uuid.toString();
            if (tenancyName == MapConstants.TenantNames.Lodos) {
                uuId = component.uuid.toString();
                const result = data.values.find((x) => x.uuid === uuId);
                return result?.value || 0;
            }
            if (_.isNil(data)) {
                return;
            }
            const result = data.values.find((x) => x.uuid === component.opcItemId.toString());
            return result?.value || 0;
        };

        const filterByOpcItemIdForHydro = (component: ComponentDto[]) => {
            if (_.isNil(data)) {
                return;
            }

            let result = 0;

            component.forEach((component) => {
                result += data.values.find((x) => x.uuid === component.opcItemId.toString()).value;
            });

            return result || 0;
        };

        if (_powerPlant.type === PowerPlantType.Hydro) {
            return filterByOpcItemIdForHydro(filteredUnitComponents);
        } else if (_powerPlant.type === PowerPlantType.Wind) {
            let powerValue = 0;
            filteredUnitComponents.forEach((component) => {
                let uuId = component.opcItemId.toString();
                if (tenancyName == MapConstants.TenantNames.Lodos) {
                    uuId = component.uuid.toString();
                }
                if (_.isNil(data)) {
                    return;
                }
                if (data.values.find((x) => x.uuid === uuId)) {
                    powerValue += data.values.find((x) => x.uuid === uuId).value;
                }
            });
            return powerValue;
        } else if (_powerPlant.type === PowerPlantType.Solar) {
            return filteredUnitComponents.reduce((resultValue, component) => resultValue + filterByOpcItemId(component), 0);
        }

        return 0;
    }

    setPlantMarkerStatusCount(
        unitStatusCodes: SchemaSnapshotStatus[],
        unitComponents: ComponentDto[],
        powerPlants: PowerPlantDto[],
        powerPlantUnits: PowerPlantUnitDto[],
        allUnitStatus: WindUnitModelStatusDto[]
    ) {
        powerPlants.forEach((powerPlant) => {
            let statusValues: number[];
            switch (powerPlant.type) {
                case PowerPlantType.Wind:
                    statusValues = Object.values(WindUnitStatus).filter((value) => typeof value === "number") as number[];
                    break;
                case PowerPlantType.Hydro:
                    statusValues = Object.values(HydroUnitStatus).filter((value) => typeof value === "number") as number[];
                    statusValues = Object.values(HydroUnitStatus).filter((value) => typeof value === "number") as number[];
                    break;
                case PowerPlantType.Solar:
                    statusValues = Object.values(SolarUnitStatus).filter((value) => typeof value === "number") as number[];
                    break;
                default:
                    statusValues = [];
                    break;
            }

            let statusStackCounter = 0;

            const modelIds: number[] = _.uniq(
                powerPlantUnits.filter((x) => x.powerPlantId === powerPlant.id).map((x) => x.modelId)
            );

            let totalStatusCountByPowerPlant = 0;

            // Tüm model ve statü kombinasyonlarının toplamlarını geçici olarak saklamak için
            const statusCountByModelAndStatus = new Map<number, Map<number, number>>(); // modelId -> (statusValue -> count)

            for (let i = 0; i < modelIds.length; i++) {
                const modelId = modelIds[i];

                let filteredUnitList = powerPlantUnits.filter((x) => x.powerPlantId === powerPlant.id && x.modelId === modelId);

                statusValues.forEach((statusValue) => {
                    // Geçici toplam
                    let currentStatusTotal = 0;

                    let filterByBaseComponent: ComponentDto[] = unitComponents.filter((x) => {
                        const isBaseComponentStatus = x.baseComponentId == BaseComponentConsts.Status;
                        const isSamePowerPlant = x.powerPlantId == powerPlant.id;

                        const matchesUnit =
                            powerPlant.type === PowerPlantType.Solar
                                ? filteredUnitList.some((y) => y.solarInverterId == x.solarInverterId)
                                : filteredUnitList.some((y) => y.id == x.powerPlantUnitId);

                        return isBaseComponentStatus && isSamePowerPlant && matchesUnit;
                    });

                    // Model ile eşleşen tüm birimleri filtrele
                    const mappedUnitModelStatuses = allUnitStatus.filter(
                        (x) => x.onePactResponse === statusValue && x.unitModelId === modelId
                    );

                    // Unit Status kodlarını eşleştirin
                    const filterByUnitStatus = filterByBaseComponent.filter((elem) =>
                        unitStatusCodes.find(
                            (x) =>
                                x.schemaName === powerPlant.schemaName &&
                                x.uuid === elem.opcItemId.toString() &&
                                mappedUnitModelStatuses.some((y) => y.mainStatus === x.value.toString())
                        )
                    );

                    // Power Plant Id'ye göre gruplama
                    const inOperationGroupByPowerPlantId = _.groupBy(filterByUnitStatus, (x) => x.powerPlantId);

                    // Mevcut Power Plant'ın durumunu alın
                    const currentSelectedStatusCount = inOperationGroupByPowerPlantId[powerPlant.id]?.length || 0;

                    // Map'e ekleyin
                    if (!statusCountByModelAndStatus.has(modelId)) {
                        statusCountByModelAndStatus.set(modelId, new Map<number, number>());
                    }

                    statusCountByModelAndStatus.get(modelId)!.set(statusValue, currentSelectedStatusCount);

                    // DOM işlemleri
                    const componentStatus = document.getElementById(
                        powerPlant.id + UnitStatusCount[powerPlant.type][statusValue]
                    );
                    const componentStatusStack = document.getElementById(
                        powerPlant.id + UnitStatusCountStack[powerPlant.type][statusValue]
                    );

                    if (currentSelectedStatusCount > 0 && componentStatus && componentStatusStack) {
                        statusStackCounter += statusStackCounter;

                        componentStatusStack.removeAttribute("hidden");

                        const plantMarkerPopup = document.getElementById("plant-marker-color-" + powerPlant.id);
                        if (!_.isNil(plantMarkerPopup)) {
                            let statusValueTotalCount = 0;
                            // `statusValue` değişkenine eşit olanların toplamını hesapla
                            statusCountByModelAndStatus.forEach((statusMap) => {
                                const count = statusMap.get(statusValue); // `statusValue`'ya eşit olan değeri al
                                if (count) {
                                    statusValueTotalCount += count; // Eğer varsa toplama ekle
                                    componentStatus.innerText = statusValueTotalCount.toString(); // O anki toplam değeri göster
                                }
                            });

                            this.flan(powerPlant, statusValue, plantMarkerPopup, statusValueTotalCount);
                        }
                    } else if (componentStatusStack) {
                        componentStatusStack.setAttribute("hidden", "true");
                    }

                    // Sonuçların grafikte gösterimi
                    const greaterGraph = document.getElementById(powerPlant.id + "-graph-status-greater-than-three");
                    const lessGraph = document.getElementById(powerPlant.id + "-graph-status-less-than-three");

                    if (statusStackCounter > 3) {
                        greaterGraph?.removeAttribute("hidden");
                        lessGraph?.setAttribute("hidden", "true");
                    } else {
                        greaterGraph?.setAttribute("hidden", "true");
                        lessGraph?.removeAttribute("hidden");
                    }

                    // Toplam durumları global olarak kaydedin
                    this.totalTurbineCountInProductionByPowerPlant.set(Number(powerPlant.id), totalStatusCountByPowerPlant);
                });
            }
        });
    }

    // Sets the data of unit marker popups on the map
    setTurbinePopupData(
        iotData: IotDataValue[],
        tenantName: string,
        unitComponents: ComponentDto[],
        powerPlantUnits: PowerPlantUnitDto[],
        powerPlants: PowerPlantDto[]
    ) {
        const powerValues = iotData.filter((elem) =>
            unitComponents.find(
                (x) => x.opcItemId.toString() == elem.uuid.toString() && x.baseComponentId === BaseComponentConsts.Power
            )
        );
        const windSpeedValues = iotData.filter((elem) =>
            unitComponents.find(
                (x) => x.opcItemId.toString() == elem.uuid.toString() && x.baseComponentId === BaseComponentConsts.WindSpeed
            )
        );

        for (const powerPlantUnit of powerPlantUnits) {
            try {
                let powerUnitComponent: ComponentDto;
                if (
                    (powerPlantUnit.powerPlantFk && powerPlantUnit.powerPlantFk.type == PowerPlantType.Solar) ||
                    powerPlantUnit.powerPlantType == PowerPlantType.Solar
                ) {
                    powerUnitComponent = unitComponents.find(
                        (x) =>
                            x.solarInverterId == powerPlantUnit.solarInverterId &&
                            x.powerPlantId == powerPlantUnit.powerPlantId &&
                            x.baseComponentId == BaseComponentConsts.Power
                    );
                } else {
                    powerUnitComponent = unitComponents.find(
                        (x) =>
                            x.powerPlantUnitId == powerPlantUnit.id &&
                            x.powerPlantId == powerPlantUnit.powerPlantId &&
                            x.baseComponentId == BaseComponentConsts.Power
                    );
                }

                let windSpeedUnitComponent = unitComponents.find(
                    (x) =>
                        x.powerPlantUnitId == powerPlantUnit.id &&
                        x.powerPlantId == powerPlantUnit.powerPlantId &&
                        x.baseComponentId == BaseComponentConsts.WindSpeed
                );

                let powerData: IotDataValue;
                let turbinePowerTag: HTMLElement;
                if (powerUnitComponent) {
                    powerData = powerValues.find((x) => x.uuid == powerUnitComponent.opcItemId.toString());
                    turbinePowerTag = document.getElementById(powerUnitComponent.opcItemId.toString());
                }
                let windSpeedData: IotDataValue;
                let turbineWindSpeedTag: HTMLElement;
                if (windSpeedUnitComponent) {
                    windSpeedData = windSpeedValues.find((x) => x.uuid == windSpeedUnitComponent.opcItemId.toString());
                    turbineWindSpeedTag = document.getElementById(windSpeedUnitComponent.opcItemId.toString());
                }

                if (turbinePowerTag || turbineWindSpeedTag) {
                    let valueToShow = "";

                    if (turbinePowerTag) {
                        const unitId = turbinePowerTag.getAttribute(MapConstants.HTMLPowerPlantUnit.TurbineId);

                        let unit: PowerPlantUnitDto;
                        if (powerPlantUnit.powerPlantType == PowerPlantType.Solar) {
                            unit = powerPlantUnits.find((x) => x.solarInverterId === Number(unitId));
                        } else {
                            unit = powerPlantUnits.find((x) => x.id === Number(unitId));
                        }

                        if (_.isNil(powerData)) {
                            return;
                        }

                        const roundedValue = _.round(powerData.value, 2);

                        valueToShow = (Number(roundedValue) / 1000).toFixed(2) + this.Mw;

                        const powerWidthElement = document.getElementById(
                            MapConstants.HTMLPowerPlantUnit.UnitMarkerPowerTag +
                                unitId +
                                ":power-plant-id-" +
                                powerPlantUnit.powerPlantId
                        );
                        if (!powerWidthElement) {
                            return;
                        }
                        const width = (roundedValue * 100) / unit.nominalPower;

                        const _width = (width / 100) * this.UnitMinWidthPx;

                        powerWidthElement.style.width = _width + "px";

                        turbinePowerTag.innerText = valueToShow;
                    }
                    if (turbineWindSpeedTag && powerPlantUnit.powerPlantFk.type == PowerPlantType.Wind) {
                        const roundedValue = _.round(windSpeedData.value, 2);
                        const unitId = turbineWindSpeedTag.getAttribute(MapConstants.HTMLPowerPlantUnit.TurbineId);
                        const unit = powerPlantUnits.find((x) => x.id === Number(unitId));
                        if ((unit.modelFk?.referenceCurve ?? undefined) == undefined) {
                            valueToShow = Number(roundedValue).toFixed(2) + this.ms;
                        } else {
                            valueToShow = Number(roundedValue).toFixed(2) + this.ms;

                            const powerWidthElement = document.getElementById(
                                MapConstants.HTMLPowerPlantUnit.UnitMarkerWindSpeedTag +
                                    unitId +
                                    ":power-plant-id-" +
                                    powerPlantUnit.powerPlantId
                            );
                            if (!powerWidthElement) {
                                return;
                            }
                            const width = (roundedValue * 100) / unit.modelFk.referenceCurve;

                            const _width = (width / 100) * this.UnitMinWidthPx;

                            const unitMarkerWindSpeedGroup = document.getElementById(
                                `unit-marker-wind-speed-group:${powerPlantUnit.id}-power-plant-id:${powerPlantUnit.powerPlantId}`
                            );

                            if (unitMarkerWindSpeedGroup) {
                                unitMarkerWindSpeedGroup.removeAttribute("hidden");
                            }

                            powerWidthElement.style.width = _width + "px";
                            turbineWindSpeedTag.innerText = valueToShow;
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    // Sets the states of the unit markers on the map
    setTurbineStatus(
        statusCodes: SchemaSnapshotStatus[],
        unitComponents: ComponentDto[],
        powerPlantUnits: PowerPlantUnitDto[],
        tenantName: string,
        allWindUnitStatus: WindUnitModelStatusDto[],
        powerPlants: PowerPlantDto[]
    ) {
        let filterStatusComponent = unitComponents.filter((x) => x.baseComponentId == BaseComponentConsts.Status);
        const filterBaseComponent = statusCodes.filter((elem) =>
            filterStatusComponent.find((x) => x.opcItemId.toString() == elem.uuid.toString())
        );

        _.forEach(filterBaseComponent, (value) => {
            let unitComponent = filterStatusComponent.find((x) => x.opcItemId.toString() == value.uuid);

            let unitId: number;
            let unit: PowerPlantUnitDto;
            if (!_.isNil(unitComponent.solarInverterId)) {
                unitId = unitComponent.solarInverterId;
                unit = powerPlantUnits.find((x) => x.solarInverterId === unitId);
            } else {
                unitId = unitComponent.powerPlantUnitId;
                unit = powerPlantUnits.find((x) => x.id === unitId);
            }

            if (_.isNil(unit)) {
                return;
            }

            let powerPlant = powerPlants.find((x) => x.id === unit.powerPlantId);

            let status = statusCodes.find((x) => x.value == value.value);

            let unitMarker = document.getElementById(MapConstants.HTMLPowerPlantUnit.UnitId + unitId);
            if (unitMarker) {
                // unitMarker.style.backgroundColor = this.getUnitStatusColor(
                //     status.value.toString(),
                //     allWindUnitStatus,
                //     powerPlant.type
                // );
            }

            let unitMarkerIcon = document.getElementById(
                MapConstants.HTMLPowerPlantUnit.UnitMarkerIcon + unitId + ":power-plant-id-" + powerPlant.id
            );
            if (unitMarkerIcon) {
                unitMarkerIcon.style.backgroundImage = this.getUnitMarkerBackgroundImageByPowerPlantType(powerPlant.type);
            }

            let unitMarkerColor = document.getElementById(
                MapConstants.HTMLPowerPlantUnit.UnitMarkerColor + unitId + ":power-plant-id-" + powerPlant.id
            );
            if (unitMarkerColor) {
                unitMarkerColor.style.backgroundColor = this.getUnitStatusColor(
                    status.value.toString(),
                    allWindUnitStatus,
                    powerPlant.type,
                    unit.modelId
                );
            }

            let plantUnitMarkerStatusContext = document.getElementById(
                MapConstants.HTMLPowerPlantUnit.UnitMarkerStatusContext + unitId.toString() + ":power-plant-id-" + powerPlant.id
            );
            if (plantUnitMarkerStatusContext) {
                plantUnitMarkerStatusContext.className = this.getClassNameForStatusTextContent(
                    status.value.toString(),
                    allWindUnitStatus,
                    powerPlant.type,
                    unit.modelId
                );
            }

            let plantUnitMarkerStatusText = document.getElementById(
                MapConstants.HTMLPowerPlantUnit.UnitMarkerStatusText + unitId.toString() + ":power-plant-id-" + powerPlant.id
            );
            if (plantUnitMarkerStatusText) {
                plantUnitMarkerStatusText.className = this.getClassNameForStatusText(
                    status.value.toString(),
                    allWindUnitStatus,
                    powerPlant.type,
                    unit.modelId
                );
                plantUnitMarkerStatusText.innerText = this.getStatusText(
                    status.value,
                    unit.modelId,
                    allWindUnitStatus,
                    powerPlant.type
                );
            }

            // production ve curtailment da iot değerlerini göster
            // diğer tüm statulerde status text göster
            let unitMarkerStatusContext = document.getElementById(
                MapConstants.HTMLPowerPlantUnit.UnitMarkerStatusContext + unitId.toString() + ":power-plant-id-" + powerPlant.id
            );
            let unitMarkerStatusDataGroup = document.getElementById(
                MapConstants.HTMLPowerPlantUnit.UnitMarkerStatusDataGroup + unitId.toString() + ":power-plant-id-" + powerPlant.id
            );
            if (!unitMarkerStatusContext || !unitMarkerStatusDataGroup) {
                return;
            }
            const onepactResponse = allWindUnitStatus.find(
                (x) => x.unitModelId == unit.modelId && x.mainStatus === status.value.toString()
            );

            // unit-marker status'u context burada açılıyor veya kapatılıyor
            if (
                onepactResponse?.onePactResponse == WindUnitStatus.InProduction ||
                onepactResponse?.onePactResponse == WindUnitStatus.Curtailment
            ) {
                unitMarkerStatusContext.setAttribute("hidden", "true");
                unitMarkerStatusDataGroup.removeAttribute("hidden");
            } else {
                unitMarkerStatusContext.removeAttribute("hidden");
                unitMarkerStatusDataGroup.setAttribute("hidden", "true");
            }
        });
    }

    getUnitStatusColor(
        mainStatus: string,
        allWindUnitStatus: WindUnitModelStatusDto[],
        powerPlantType: PowerPlantType,
        unitModelId: number
    ) {
        if (!allWindUnitStatus || !UnitStatusData[powerPlantType]) {
            return;
        }

        const status = allWindUnitStatus.find((x) => x.unitModelId == unitModelId && x.mainStatus === mainStatus.toString());

        if (status) {
            return UnitStatusData[powerPlantType][status.onePactResponse];
        }
    }

    getClassNameForStatusIcon(
        mainStatus: string,
        allWindUnitStatus: WindUnitModelStatusDto[],
        powerPlantType: PowerPlantType,
        unitModelId: number
    ) {
        if (!mainStatus) {
            return;
        }

        const status = allWindUnitStatus.find((x) => x.unitModelId == unitModelId && x.mainStatus === mainStatus.toString());

        if (!status) {
            return;
        }
        const className = UnitStatusClasses[powerPlantType][status.onePactResponse];
        if (!_.isNil(className)) {
            return className;
        }
    }

    getClassNameForStatusText(
        mainStatus: string,
        allWindUnitStatus: WindUnitModelStatusDto[],
        powerPlantType: PowerPlantType,
        unitModelId: number
    ) {
        const status = allWindUnitStatus.find((x) => x.unitModelId == unitModelId && x.mainStatus === mainStatus.toString());

        if (!status) {
            return;
        }

        return UnitStatusTextClasses[powerPlantType][status.onePactResponse];
    }

    getClassNameForStatusTextContent(
        mainStatus: string,
        allWindUnitStatus: WindUnitModelStatusDto[],
        powerPlantType: PowerPlantType,
        unitModelId: number
    ) {
        const status = allWindUnitStatus.find((x) => x.unitModelId == unitModelId && x.mainStatus === mainStatus.toString());

        if (!status) {
            return;
        }

        return UnitStatusTextContentClasses[powerPlantType][status.onePactResponse];
    }

    getStatusText(value: number, modelId: number, allWindUnitStatus: WindUnitModelStatusDto[], powerPlantType: PowerPlantType) {
        const status = allWindUnitStatus.find((x) => x.mainStatus === value.toString() && x.unitModelId === modelId);

        if (!status) {
            return;
        }

        return UnitStatusText[powerPlantType][status.onePactResponse];
    }

    getPowerPlantFilteredUnitComponents(_powerPlant: PowerPlantDto, componentList: ComponentDto[]): ComponentDto[] {
        if (_powerPlant.type === PowerPlantType.Wind) {
            return componentList.filter(
                (x) =>
                    x.baseComponentId == BaseComponentConsts.Power && x.powerPlantUnitId != 0 && x.powerPlantId === _powerPlant.id
            );
        } else if (_powerPlant.type === PowerPlantType.Solar) {
            return componentList.filter(
                (x) =>
                    x.baseComponentId == BaseComponentConsts.Power &&
                    x.powerPlantId === _powerPlant.id &&
                    !_.isNil(x.powerPlantUnitId)
            );
        } else if (_powerPlant.type === PowerPlantType.Hydro) {
            return componentList.filter(
                (x) => x.baseComponentId == BaseComponentConsts.Power && x.powerPlantId === _powerPlant.id
            );
        }
    }

    getUnitFilteredUnitComponents(unitId: number, componentList: ComponentDto[]): ComponentDto[] {
        return componentList.filter((x) => x.baseComponentId == BaseComponentConsts.Power && x.powerPlantUnitId === unitId);
    }

    getUnitFilteredUnitComponentsForSolarInverter(solarInverterId: number, componentList: ComponentDto[]): ComponentDto[] {
        return componentList.filter(
            (x) => x.baseComponentId == BaseComponentConsts.Power && x.solarInverterId === solarInverterId
        );
    }

    async getLocalStorageItemAsync(key, callback) {
        return new Promise((resolve) => {
            this._localStorageService.getItem(key, (err, data) => {
                callback(data);
                resolve(data);
            });
        });
    }

    private getUnitMarkerBackgroundImageByPowerPlantType(type: PowerPlantType) {
        const markerImagePath = "assets/common/images/map/plant-unit-marker/";

        switch (type) {
            case PowerPlantType.Wind:
                return `url(${markerImagePath}plant-unit-marker-building-wind-turbine.svg)`;
            case PowerPlantType.Hydro:
                return `url(${markerImagePath}plant-unit-marker-building-hydro.svg)`;
            case PowerPlantType.Solar:
                return `url(${markerImagePath}plant-unit-marker-building-solar.svg)`;
        }
    }

    private setPlantMarkerBackgroundColor(
        plantMarkerPopup: HTMLElement,
        statusValue: number,
        count: number,
        color: string,
        powerPlant?: PowerPlantDto
    ) {
        if (count > 0) {
            plantMarkerPopup.style.backgroundColor = color;
            abp.event.trigger("plant-marker-color-changed", {
                powerPlant: powerPlant,
                onePactResponse: statusValue,
            });
        }
    }

    private flan(powerPlant, statusValue, plantMarkerPopup, currentSelectedStatusCount) {
        if (currentSelectedStatusCount <= 0) {
            return;
        }

        const setMarkerColor = (color: string) => {
            this.setPlantMarkerBackgroundColor(plantMarkerPopup, statusValue, currentSelectedStatusCount, color, powerPlant);
        };

        const updateWindPowerPlantStatusState = (_warningExist, _maintenanceExist, _outoff) => {
            this.windPowerPlantStatusState.set(powerPlant.id, {
                warningExist: _warningExist,
                maintenanceExist: _maintenanceExist,
                outOfCommunicationExist: _outoff,
            });
        };

        switch (powerPlant.type) {
            case PowerPlantType.Wind:
                if (!_.isNil(this.windPowerPlantStatusState.get(powerPlant.id))) {
                    const { warningExist, maintenanceExist, outOfCommunicationExist } = this.windPowerPlantStatusState.get(
                        powerPlant.id
                    );
                    if (statusValue === WindUnitStatus.Warning) {
                        setMarkerColor("#ff5630");
                        updateWindPowerPlantStatusState(true, maintenanceExist, outOfCommunicationExist);
                    } else if (!maintenanceExist && statusValue === WindUnitStatus.Maintenance) {
                        setMarkerColor("#ffab00");
                        updateWindPowerPlantStatusState(warningExist, true, outOfCommunicationExist);
                    } else if (!outOfCommunicationExist && statusValue === WindUnitStatus.OutOfCommunication) {
                        setMarkerColor("#ffffff7a");
                        updateWindPowerPlantStatusState(warningExist, maintenanceExist, true);
                    } else if (statusValue === WindUnitStatus.InProduction) {
                        setMarkerColor("#36b37e");
                    } else if (statusValue === WindUnitStatus.Curtailment) {
                        setMarkerColor("#3366ff");
                    }
                } else {
                    this.windPowerPlantStatusState.set(powerPlant.id, {
                        warningExist: false,
                        maintenanceExist: false,
                        outOfCommunicationExist: false,
                    });
                }
                break;
            case PowerPlantType.Solar:
                if (statusValue === SolarUnitStatus.Warning) {
                    setMarkerColor("#ff5630");
                } else if (statusValue === SolarUnitStatus.InProduction) {
                    setMarkerColor("#36b37e");
                }
                break;

            case PowerPlantType.Hydro:
                const hydroColors = {
                    [HydroUnitStatus.Warning]: "#ff5630",
                    [HydroUnitStatus.Maintenance]: "#ffab00",
                    [HydroUnitStatus.OutOfCommunication]: "#ffffff7a",
                    [HydroUnitStatus.InProduction]: "#36b37e",
                    [HydroUnitStatus.Standstill]: "#00B8D9",
                };
                const hydroColor = hydroColors[statusValue];
                if (hydroColor) {
                    setMarkerColor(hydroColor);
                }
                break;

            default:
                // Diğer santral türleri için işlem
                break;
        }
    }
}
