import { Component, Injector, Input, OnInit } from "@angular/core";
import * as Highcharts from "@node_modules/highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsData from "highcharts/modules/data";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import { DailyWindSpeedAndWindDirection } from "@shared/service-proxies/service-proxies";
import HC_exporting from "@node_modules/highcharts/modules/exporting";
import { DashboardItemWidgetType } from "@app/shared/common/widget/widget-consts";
import { ChartService } from "@app/shared/common/dashboard-charts/chart.service";

HighchartsMore(Highcharts);
HighchartsData(Highcharts);
HighchartsAccessibility(Highcharts);
HC_exporting(Highcharts);

export interface WindRoseFrequencyItem {
    directionName: string;
    speedRange: string;
    total: number;
    tenMinutesDataCount: number;
}

@Component({
    selector: "app-wind-rose-chart",
    templateUrl: "./wind-rose-chart.component.html",
    styleUrls: ["./wind-rose-chart.component.scss"],
})
export class WindRoseChartComponent implements OnInit {
    @Input() divId: string;
    @Input() chartName: string;
    @Input() windRoseData: DailyWindSpeedAndWindDirection;
    @Input() windRoseDataList: DailyWindSpeedAndWindDirection[];
    @Input() isMultiple: boolean = false;
    @Input() completedEventId: string;

    chart: Highcharts.Chart;
    options: Highcharts.Options = {
        data: {
            rows: [
                ["Direction", "< 0.5 m/s", "0.5-2 m/s", "2-4 m/s", "4-6 m/s", "6-8 m/s", "8-10 m/s", "> 10 m/s"],
                ["N", 0, 0, 0, 0, 0, 0, 0],
                ["NNE", 0, 0, 0, 0, 0, 0, 0],
                ["NE", 0, 0, 0, 0, 0, 0, 0],
                ["ENE", 0, 0, 0, 0, 0, 0, 0],
                ["E", 0, 0, 0, 0, 0, 0, 0],
                ["ESE", 0, 0, 0, 0, 0, 0, 0],
                ["SE", 0, 0, 0, 0, 0, 0, 0],
                ["SSE", 0, 0, 0, 0, 0, 0, 0],
                ["S", 0, 0, 0, 0, 0, 0, 0],
                ["SSW", 0, 0, 0, 0, 0, 0, 0],
                ["SW", 0, 0, 0, 0, 0, 0, 0],
                ["WSW", 0, 0, 0, 0, 0, 0, 0],
                ["W", 0, 0, 0, 0, 0, 0, 0],
                ["WNW", 0, 0, 0, 0, 0, 0, 0],
                ["NW", 0, 0, 0, 0, 0, 0, 0],
                ["NNW", 0, 0, 0, 0, 0, 0, 0],
            ],
        },

        chart: {
            polar: true,
            type: "column",
        },

        title: {
            text: null,
        },

        pane: {
            size: "85%",
        },

        legend: {
            align: "right",
            verticalAlign: "top",
            y: 100,
            layout: "vertical",
        },

        xAxis: {
            tickmarkPlacement: "on",
        },

        yAxis: {
            // min: 0,
            // max: 12,
            endOnTick: false,
            showLastLabel: true,
            title: {
                text: "Speed (m/s)",
            },
            labels: {
                formatter: function () {
                    return Number(this.value).toFixed(2) + "m/s";
                },
            },
            reversedStacks: false,
        },

        tooltip: {
            valueSuffix: "m/s",
        },

        plotOptions: {
            series: {
                stacking: "normal",
                shadow: false,
                pointPlacement: "on",
            },
        },
        credits: {
            enabled: false,
        },
    };
    private intervalId: any;

    constructor(injector: Injector, private _chartService: ChartService) {}

    ngOnInit(): void {
        let chartElement = document.querySelector("#" + this.divId + " #" + this.chartName);
        if (this.isMultiple) {
            //@ts-ignore
            this.options.yAxis.labels.format = "{value}%";
            //@ts-ignore
            this.options.yAxis.labels.formatter = undefined;
            //@ts-ignore
            this.options.yAxis.title.text = "";
            this.options.tooltip.valueSuffix = "%";
        }
        // @ts-ignore
        this.chart = Highcharts.chart(chartElement, this.options);

        this._chartService.retryUntilSuccess(
            1000, // İnterval (ms)
            20, // Maksimum deneme sayısı
            () => Array.isArray(Highcharts.charts) &&
                Highcharts.charts.some((v) => v && v["renderTo"]?.id === this.chartName), // Koşul
            () => {
                const windRoseChart = Highcharts.charts.find(
                    (v) => v && v["renderTo"]?.id === this.chartName
                );
                if (windRoseChart) {
                    windRoseChart.reflow();
                }
            }
        );

        if (this.isMultiple) {
            this.setMultipleWindData(chartElement);
        } else {
            this.intervalId = setInterval(() => {
                this.setSingleWindData(chartElement);
            }, 1000);
        }
    }

    setSingleWindData(chartElement: Element) {
        if (chartElement && this.windRoseData && this.windRoseData.windDirection && this.windRoseData.windSpeed) {
            let direction = this.getDirection(this.windRoseData.windDirection);
            let speedRangeIndex = this.getWindSpeedRangeIndex(this.windRoseData.windSpeed);
            // Clear row column values
            for (let i = 1; i < this.options.data.rows.length; i++) {
                for (let j = 1; j < this.options.data.rows[i].length; j++) {
                    this.options.data.rows[i][j] = 0;
                }
            }

            const row = this.options.data.rows.find((x) => x[0].toString() == direction);
            row[speedRangeIndex] = Number(this.windRoseData.windSpeed).toFixed(2);
            this.chart.data.update(this.options.data, true);
            setTimeout(() => {
                abp.event.trigger(this.completedEventId, {
                    isCompleted: true,
                    highChart: this.chart,
                    type: DashboardItemWidgetType.chart,
                });
            }, 500);
            clearInterval(this.intervalId);
        }
    }

    setMultipleWindData(chartElement: Element) {
        if (chartElement && this.windRoseDataList) {
            const frequencyList = this.getWindRoseFrequencyList();
            frequencyList.forEach((freq) => {
                let speedRangeIndex = this.getWindSpeedRangeIndexFromText(freq.speedRange);

                const row = this.options.data.rows.find((x) => x[0].toString() == freq.directionName);
                row[speedRangeIndex] = Number((freq.tenMinutesDataCount * 100) / this.windRoseDataList.length).toFixed(2);
                this.chart.data.update(this.options.data, true);
            });
            setTimeout(() => {
                abp.event.trigger(this.completedEventId, {
                    isCompleted: true,
                    highChart: this.chart,
                    type: DashboardItemWidgetType.chart,
                });
            }, 500);
        }
    }

    getWindRoseFrequencyList(): WindRoseFrequencyItem[] {
        const data: WindRoseFrequencyItem[] = [];
        this.windRoseDataList.forEach((x) => {
            let directionName = this.getDirection(x.windDirection);
            let speedRange = this.getWindSpeedRange(x.windSpeed);
            const item = data.find((x) => x.directionName == directionName && x.speedRange == speedRange);
            if (item) {
                item.total += x.windSpeed;
                item.tenMinutesDataCount++;
            } else {
                data.push({
                    speedRange: speedRange,
                    directionName: directionName,
                    total: x.windSpeed,
                    tenMinutesDataCount: 1,
                });
            }
        });
        return data;
    }

    getWindSpeedRange(speed: number): string {
        if (speed < 0.5) {
            return "< 0.5 m/s";
        } else if (speed >= 0.5 && speed < 2) {
            return "0.5-2 m/s";
        } else if (speed >= 2 && speed < 4) {
            return "2-4 m/s";
        } else if (speed >= 4 && speed < 6) {
            return "4-6 m/s";
        } else if (speed >= 6 && speed < 8) {
            return "6-8 m/s";
        } else if (speed >= 8 && speed < 10) {
            return "8-10 m/s";
        } else {
            return "> 10 m/s";
        }
    }

    getWindSpeedRangeIndex(speed: number): number {
        if (speed < 0.5) {
            return 1;
        } else if (speed >= 0.5 && speed < 2) {
            return 2;
        } else if (speed >= 2 && speed < 4) {
            return 3;
        } else if (speed >= 4 && speed < 6) {
            return 4;
        } else if (speed >= 6 && speed < 8) {
            return 5;
        } else if (speed >= 8 && speed < 10) {
            return 6;
        } else {
            return 7;
        }
    }

    getWindSpeedRangeIndexFromText(speedText: string): number {
        if (speedText == "< 0.5 m/s") {
            return 1;
        } else if (speedText == "0.5-2 m/s") {
            return 2;
        } else if (speedText == "2-4 m/s") {
            return 3;
        } else if (speedText == "4-6 m/s") {
            return 4;
        } else if (speedText == "6-8 m/s") {
            return 5;
        } else if (speedText == "8-10 m/s") {
            return 6;
        } else {
            return 7;
        }
    }

    getDirection(direction: number): string {
        // 0 is North, I guess...
        if ((direction >= 0 && direction < 11.25) || (direction >= 348.75 && direction < 360)) {
            return "N";
        } else if (direction >= 11.25 && direction < 33.75) {
            return "NNE";
        } else if (direction >= 33.75 && direction < 56.25) {
            return "NE";
        } else if (direction >= 56.25 && direction < 78.75) {
            return "ENE";
        } else if (direction >= 78.75 && direction < 101.25) {
            return "E";
        } else if (direction >= 101.25 && direction < 123.75) {
            return "ESE";
        } else if (direction >= 123.75 && direction < 146.25) {
            return "SE";
        } else if (direction >= 146.25 && direction < 168.75) {
            return "SSE";
        } else if (direction >= 168.75 && direction < 191.25) {
            return "S";
        } else if (direction >= 191.25 && direction < 213.75) {
            return "SSW";
        } else if (direction >= 213.75 && direction < 236.25) {
            return "SW";
        } else if (direction >= 236.25 && direction < 258.75) {
            return "WSW";
        } else if (direction >= 258.75 && direction < 281.25) {
            return "W";
        } else if (direction >= 281.25 && direction < 303.75) {
            return "WNW";
        } else if (direction >= 303.75 && direction < 326.25) {
            return "NW";
        } else if (direction >= 326.25 && direction < 348.75) {
            return "NNW";
        } else {
            return "N";
        }
    }
}
