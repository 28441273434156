import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import * as Highcharts from "@node_modules/highcharts";
import bullet from "highcharts/modules/bullet";
import HC_exporting from "@node_modules/highcharts/modules/exporting";
import * as _ from "lodash";
import { DashboardItemWidgetType } from "@app/shared/common/widget/widget-consts";

let More = require("highcharts/highcharts-more");

More(Highcharts);
bullet(Highcharts);
HC_exporting(Highcharts);

@Component({
    selector: "app-bulletgraph-chart",
    templateUrl: "./bulletgraph-chart.component.html",
    styleUrls: ["./bulletgraph-chart.component.css"],
})
export class BulletgraphChartComponent implements OnInit, OnChanges {
    @Input() divId: string;
    @Input() chartName: string;
    @Input() data: number;
    @Input() maxLimit: number;
    @Input() unitType: string;
    @Input() completedEventId: string;

    highchart: any;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            // @Input değeri değiştiğinde yapılacak işlemleri burada gerçekleştirin

            if (!_.isNil(this.data)) {
                let point;

                if (this.highchart) {
                    point = this.highchart.series[0].points[0];

                    point.update(this.data);
                    setTimeout(() => {
                        abp.event.trigger(this.completedEventId, {
                            isCompleted: true,
                            highChart: this.highchart,
                            type: DashboardItemWidgetType.chart,
                        });
                    }, 500);
                }
            }
        }
    }

    getChartOptions() {
        return {
            chart: {
                inverted: true,
                marginLeft: 10,
                type: "bullet",
            },
            title: {
                text: null,
            },
            legend: {
                enabled: false,
            },
            yAxis: {
                gridLineWidth: 0,
            },
            plotOptions: {
                series: {
                    pointPadding: 0.25,
                    borderWidth: 0,
                    color: "#000",
                    targetOptions: {
                        width: "80%",
                    },
                },
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
        };
    }

    ngOnInit() {
        let chart = document.querySelector("#" + this.divId + " #" + this.chartName);

        // @ts-ignore
        this.highchart = Highcharts.chart(
            // @ts-ignore
            chart,
            Highcharts.merge(this.getChartOptions(), {
                chart: {
                    marginTop: 0,
                    height: "20%",
                },
                xAxis: {
                    categories: ['<span class="hc-cat-title"></span><br/>'],
                },
                yAxis: {
                    min: 0,
                    max: this.maxLimit,
                    endOnTick: false,
                    tickPositions: [
                        0,
                        Math.round(this.maxLimit / 4),
                        Math.round(this.maxLimit / 2),
                        Math.round(this.maxLimit * 0.75),
                        this.maxLimit,
                    ],
                    plotBands: [
                        {
                            from: 0,
                            to: this.maxLimit / 2,
                            color: "#666",
                        },
                        {
                            from: this.maxLimit / 2,
                            to: this.maxLimit * 0.75,
                            color: "#999",
                        },
                        {
                            from: this.maxLimit * 0.75,
                            to: this.maxLimit,
                            color: "#bbb",
                        },
                    ],
                    labels: {
                        formatter:
                            this.chartName == "capacity_chart" || this.chartName == "daily_availability_chart"
                                ? function () {
                                      return this.value + "%";
                                  }
                                : function () {
                                      return Number(this.value).toFixed(2);
                                  },
                        style: {
                            fontSize: "14px",
                        },
                    },
                    title: null,
                },
                series: [
                    {
                        name: this.chartName == "production_chart" ? "production" : "capacity factor",
                        data: [
                            {
                                y: this.data > 0 ? this.data : 0,
                            },
                        ],
                    },
                ],
                tooltip: {
                    pointFormat:
                        this.chartName == "production_chart" ? "<b>{point.y} " + this.unitType + "</b>" : "<b>{point.y} %</b>",
                },
            })
        );

        if (Highcharts.charts.find((v) => v["renderTo"].id == this.chartName)) {
            Highcharts.charts.find((v) => v["renderTo"].id == this.chartName).reflow();
        }
    }
}
