import { Injectable, Injector } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { LocalStorageService } from "@shared/utils/local-storage.service";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ReportSection, ReportSectionType } from "@app/main/reports/report-overview/report-overview.model";
import * as _ from "lodash";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({ providedIn: 'root' })
export class ReportOverviewBaseService extends AppComponentBase {
    contentList = [];

    constructor(
        injector: Injector,
        private _localStorageService: LocalStorageService,
    ) {
        super(injector);
    }

    setOverflowVisibleForClasses(classNames: string[]) {
        classNames.forEach((className) => {
            const element = document.querySelector("." + className) as HTMLElement;
            if (element) {
                element.style.overflow = "visible";
            }
        });
    }

    async getLocalStorageItemAsync(key, callback) {
        return new Promise((resolve) => {
            this._localStorageService.getItem(key, (err, data) => {
                callback(data);
                resolve(data);
            });
        });
    }


    buildTree(sections: ReportSection[]): ReportSection[] {
        const sectionMap: Record<number, ReportSection> = {};

        // Create a mapping of sections by their id
        sections.forEach((section) => {
            sectionMap[section.id] = section;
            section.children = [];
        });

        // Build the tree hierarchy
        const rootSections: ReportSection[] = [];
        sections.forEach((section) => {
            if (section.isRoot) {
                rootSections.push(section);
            } else {
                const parentSection = sectionMap[section.rootId];
                if (parentSection) {
                    parentSection.children.push(section);
                }
            }
        });

        return rootSections;
    }



    collectContentTitles(sections: ReportSection[]) {
        this.contentList = [];
        let tocTree = this.buildTree(
            sections.filter((x) => x.isTOCSelected === true && x.isHidden === false)
        );

        tocTree = tocTree.filter((x) => x.components[0].type !== ReportSectionType.TableOfContents);

        for (const root of tocTree) {
            this.contentList.push({
                text: `${!_.isNil(root.header) ? root.header : ""}`,
                page: root.index + 1,
            });
            for (const child of root.children) {
                this.contentList.push({
                    text: `${!_.isNil(child.header) ? child.header : ""}`,
                    page: child.index + 1,
                });
            }
        }
    }

    // İçerik tablosunu oluşturmak için bir fonksiyon
    createTableOfContents() {
        const tocContent = this.contentList.map((item) => {
            return [item.text, item.page];
        });

        // Tablo tanımı
        const table = {
            table: {
                headerRows: 1,
                widths: ["*", "auto"],
                body: [
                    [
                        { text: "Contents", bold: true },
                        { text: "Page", bold: true },
                    ],
                    ...tocContent,
                ],
            },
        };

        return table;
    }

    // PDF içeriğini oluşturma
    createPdfContent() {
        // İçerik tablosunu oluştur
        const tableOfContents = this.createTableOfContents();

        // Tüm içerikleri birleştir
        return [tableOfContents];
    }
}
