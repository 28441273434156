import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import * as Highcharts from "@node_modules/highcharts";
import { ChartService } from "@app/shared/common/dashboard-charts/chart.service";
import { DashboardItemWidgetType } from "@app/shared/common/widget/widget-consts";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AxisLabelsFormatterCallbackFunction, SeriesHeatmapOptions, TooltipOptions } from "@node_modules/highcharts";
import HighchartsBoost from "highcharts/modules/boost";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { DateTime } from "luxon";
import { DateTimeService } from "@app/shared/common/timing/date-time.service";

// Boost ve Heatmap modüllerini etkinleştirme
HighchartsBoost(Highcharts);
HighchartsHeatmap(Highcharts);

export interface DefaultHeatMapSeriesData {
    categories?: string[];
    series?: SeriesHeatmapOptions[];
    formatter?: AxisLabelsFormatterCallbackFunction;
    tooltip?: TooltipOptions;
    xAxisTitle?: string;
    yAxisTitle?: string;
}

@Component({
    selector: "app-default-heatmap-chart",
    templateUrl: "./default-heatmap-chart.component.html",
    styleUrls: ["./default-heatmap-chart.component.css"],
})
export class DefaultHeatmapChartComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input() divId: string;
    @Input() chartName: string;
    @Input() heatMapSeriesData: DefaultHeatMapSeriesData;
    @Input() completedEventId: string;
    @Input() legendEnabled: boolean = true;
    @Input() yCategories: string[];
    @Input() chartHeight: number = 300;
    @Input() tooltipFormatter: (arg: Highcharts.TooltipFormatterContextObject,timeZoneOffset:number) => string;

    chart: Highcharts.Chart;
    options: Highcharts.Options;

    constructor(injector: Injector, private _chartService: ChartService,private _dateTimeService: DateTimeService) {
        super(injector);
    }

    ngOnInit(): void {
        this.setOptions();
        let chartElement = document.querySelector("#" + this.divId + " #" + this.chartName);

        // @ts-ignore
        this.chart = Highcharts.chart(chartElement, this.options);

        // Retry işlemini gerçekleştiren bir fonksiyon tanımlayın
        this._chartService.retryUntilSuccess(
            1000, // İnterval (ms)
            20, // Maksimum deneme sayısı
            () => Array.isArray(Highcharts.charts) &&
                Highcharts.charts.some((v) => v && v["renderTo"]?.id === this.chartName), // Koşul
            () => {
                const defaultHeatMapChart = Highcharts.charts.find(
                    (v) => v && v["renderTo"]?.id === this.chartName
                );
                if (defaultHeatMapChart) {
                    defaultHeatMapChart.reflow();
                    setTimeout(() => {
                        abp.event.trigger(this.completedEventId, {
                            isCompleted: true,
                            highChart: this.chart,
                            type: DashboardItemWidgetType.chart,
                        });
                    }, 500);
                }
            }
        );


    }

    ngOnChanges(changes: SimpleChanges) {}

    setOptions() {
        let that = this;
        // @ts-ignore
        this.options = {
            chart: {
                type: "heatmap",
            },

            credits: {
                enabled: false,
            },
            title: {
                text: "",
                style: {
                    fontSize: "3px",
                },
            },

            xAxis: {
                categories: Array.from({ length: 24 }, (_, i) => i.toString()), // 0-23 saat aralığını oluşturur
                gridLineWidth: 1, // X ekseni ızgara çizgilerinin kalınlığı
                gridLineColor: "#e0e0e0", // X ekseni ızgara çizgilerinin rengi
            },

            yAxis: {
                startOnTick: false,
                endOnTick: false,
                title: {
                    text: "Days"
                },
                categories: this.yCategories,
                reversed: true,
                gridLineWidth: 1,
                labels: {
                    style: {
                        fontSize: '10px', // Daha küçük bir yazı boyutu ayarla
                    },
                    step: 1,
                },
                max: this.yCategories.length - 1, // Tüm kategorileri göstermek için max ve min ayarla
                min: 0,
            },
            tooltip: {
                formatter: function (tooltip): string {
                    return that.tooltipFormatter(this,that._dateTimeService.getTimezoneOffset(abp.timing.timeZoneInfo.iana.timeZoneId) / 60);
                },
            },
            colorAxis: {
                min: 0,
                minColor: "#f8f2ac",
                maxColor: "#c4463a",
            },

            legend: {
                align: "right",
                layout: "vertical",
                margin: 0,
                verticalAlign: "top",
                y: 25,
                symbolHeight: 280,
            },

            series: this.heatMapSeriesData.series,

            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 500,
                        },
                        chartOptions: {
                            yAxis: {
                                labels: {
                                    format: "{substr value 0 1}",
                                },
                            },
                        },
                    },
                ],
            },
        };
    }
}
