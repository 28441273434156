<div *ngIf="this.isDrawerOpen" class="map-detail-drawer-pp screen">
    <header class="header">
        <div class="actions">
            <article class="icon-button">
                <div (click)="closeDrawer()" class="ic_tabler_layout-dashboard_close"></div>
            </article>
        </div>
    </header>
    <div class="x">
        <div class="asset-card">
            <div class="frame-91 frame">
                <div
                    [className]="getClassNameForIconContent(this.assetDetail.mainStatus, this.assetDetail.unitModelId)">
                    <div [class]="getSearchIcon(this.assetDetail.isPowerPlant)" [style]="getUnitIcon()"></div>
                </div>
                <div class="content-1">
                    <div class="frame-107 frame">
                        <div
                            class="namerand valign-text-middle publicsans-bold-charade-24px">{{ this.assetDetail.name }}
                        </div>
                        <div class="stack-1">
                            <div class="links">
                                <div class="link">
                                    <div
                                        class="text-3 publicsans-normal-pale-sky-12px">{{ this.assetDetail.region }}
                                    </div>
                                </div>
                                <div class="links-item"></div>
                                <div class="link-3">
                                    <div
                                        class="text-4 publicsans-normal-pale-sky-12px">{{ this.assetDetail.country }}
                                    </div>
                                </div>
                                <div class="links-item"></div>
                                <div class="link-3">
                                    <div
                                        class="text-5 publicsans-normal-pale-sky-12px">{{ this.assetDetail.company }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="general-app-widget-summary general-app-widget-2">
            <div class="x-1">
                <div class="total-active-users publicsans-semi-bold-charade-14px">Active Power</div>
                <h1 class="number-shortrand number">{{ this.currentProduction }} MW</h1>
            </div>
            <div class="frame-11 frame">
                <img class="chart-sparkline"
                     src="assets/common/images/map/plant-detail-drawer/chart-sparkline.svg"
                     alt="Chart/Sparkline">
                <div class="stack">
                    <div class="icon">
                        <img class="iconsic"
                             src="assets/common/images/map/plant-detail-drawer/icons-ic-trending-up.svg"
                             alt="icons/ic_trending_up">
                    </div>
                </div>
            </div>
        </div>
        <div class="general-app-widget-summary general-app-widget-2"
             *ngIf="this.assetDetail.powerPlantType === PowerPlantType.Hydro">
            <div class="x-2">
                <div class="total-active-users-1 total-active-users-3 publicsans-semi-bold-charade-14px">Water Level
                </div>
                <div class="number-shortrand number">{{ this.currentWaterLevel }} M</div>
            </div>

        </div>
        <div class="general-app-widget-summary general-app-widget-2"
             *ngIf="this.assetDetail.powerPlantType === PowerPlantType.Wind">
            <div class="x-2">
                <div class="total-active-users-1 total-active-users-3 publicsans-semi-bold-charade-14px">Wind Speed
                </div>
                <div class="number-shortrand number">{{ this.currentWindSpeed }} m/s</div>
            </div>
            <div class="frame-11 frame">
                <img class="chart-sparkline"
                     src="assets/common/images/map/plant-detail-drawer/chart-sparkline.svg"
                     alt="Chart/Sparkline">
                <div class="stack">
                    <div class="icon">
                        <img class="iconsic"
                             src="assets/common/images/map/plant-detail-drawer/icons-ic-trending-up.svg"
                             alt="icons/ic_trending_up">
                    </div>
                </div>
            </div>
        </div>
        <div class="recent-notifications">
            <div class="title-1">
                <div class="total-active-users-2 total-active-users-3">Recent Status Changes</div>
                <div class="button">
                    <div class="label-4" (click)="navigateNotifications()">See All</div>
                    <img class="end-icon" src="assets/common/images/map/plant-detail-drawer/end-icon.svg"
                         alt="end icon">
                </div>
            </div>
            <div class="notifications">
                <div class="timeline" *ngFor="let notification of unitStatusNotificationRecent">
                    <div class="notification-separator d-flex align-items-center">
                        <div class="x-3">
                            <div [className]="getUnitStatusIconColor(notification)">
                                <div class="ic_tabler_building-wind-turbine" [style]="getUnitIcon()"></div>
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <div class="title publicsans-bold-charade-14px">
                            <span *ngIf="assetDetail.isPowerPlant">{{ notification.powerPlantUnitName }}</span>
                        </div>
                        <div class="title publicsans-semi-bold-charade-14px text-truncate"
                             [title]="notification.downtime.mainStatus + ': ' + (notification.mainStatusText ?? '-')">
                            <span>{{ notification.downtime.mainStatus }}: </span>
                            {{ notification.mainStatusText ?? "-" }}
                        </div>
                        <div *ngIf="notification.downtime.subStatus"
                             class="title publicsans-normal-charade-14px text-truncate"
                             [title]="notification.downtime.subStatus + ': ' + (notification.downtime.description ?? '-')">
                            <span>{{ notification.downtime.subStatus + ": " + (notification.downtime.description ?? "-") }}</span>
                        </div>
                        <div class="text-2">
                            <div class="text-6">
                                <img class="iconsic"
                                     src="assets/common/images/map/plant-detail-drawer/icons-ic-clock-outline.svg"
                                     alt="icons/ic_clock_outline">
                                <div
                                    class="x07-sep-2020 publicsans-normal-pale-sky-12px">{{ getDateForDrawer(notification.downtime.startDate) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
