import { Component, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { PowerPlanUnitFilters } from "@app/main/reports/report-overview/report-overview.model";

@Component({
    selector: 'app-power-plant-unit-selection',
    templateUrl: './power-plant-unit-selection.component.html',
    styleUrls: ['./power-plant-unit-selection.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: PowerPlantUnitSelectionComponent,
            multi: true
        },
    ]
})
export class PowerPlantUnitSelectionComponent implements ControlValueAccessor {
    @Input() activated = true;
    @Input() powerPlantUnits: PowerPlanUnitFilters[] = [];
    @Input() multiple = false;

    value: string;
    isDisabled: boolean;

    private onChange: any;
    private onTouched: any;

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(value: number): void {
        this.value = value?.toString();
    }

    getAssetButtonClassName(defaultClass: string): string {
        return this.activated ? "button-active" : defaultClass;
    }

    getAssetLabelClassName(defaultClass: string) {
        return this.activated ? "label-active" : defaultClass;
    }

    getAssetSrc(defaultUrl: string) {
        return this.activated ? "assets/common/images/report/widget/end-icon-1.svg" : defaultUrl;
    }

    getAssetIcon(startIcon: string) {
        return this.activated ? startIcon + "-active" : startIcon;
    }

    onPowerPlantUnitSelect(id: number, event: MouseEvent) {

        event.stopPropagation(); // Olayın dropdown'ı kapatmasını engelle


        const selectedUnit = this.powerPlantUnits.find(x => x.id === id);

        if (!selectedUnit) return; // Eğer geçersiz bir id verilmişse çık.

        if (this.multiple) {
            // Seçiliyse pasif yap, değilse aktif yap
            selectedUnit.selected = !selectedUnit.selected;
        } else {
            // Tekli seçim modunda diğerlerini pasif yap ve tıklananı tersine çevir
            for (let powerPlantUnit of this.powerPlantUnits) {
                powerPlantUnit.selected = powerPlantUnit.id === id ? !powerPlantUnit.selected : false;
            }
        }

    }


    isAllFiltersSelected() {
        return this.powerPlantUnits.every(x => x.selected);
    }

    toggleOptionAllSelect() {
        event.stopPropagation(); // Olayın dropdown'ı kapatmasını engelle

        // Herhangi bir "selected" değeri false ise, hepsini true yap
        const hasAnyUnselected = this.powerPlantUnits.some(item => !item.selected);

        // Eğer bir tane bile "selected" false ise hepsini true, aksi halde hepsini false yap
        this.powerPlantUnits = this.powerPlantUnits.map(item => ({
            ...item,
            selected: hasAnyUnselected // Tüm değerleri true yap veya false yap
        }));
    }


    private updateSelectedValues() {
        this.onTouched();
        this.onChange(this.powerPlantUnits.filter(x => x.selected).map(x => x.id));
    }

    getLabels() {
        if (this.isAllFiltersSelected()) return 'All';

        const selected = this.powerPlantUnits.filter(x => x.selected);

        if (!selected.length) return 'Unit';

        return selected.map(x => x.value).join(', ');
    }

    onDropdownHidden() {
        // Dropdown kapandığında seçilen değerleri günceller
        this.updateSelectedValues();
    }
}
