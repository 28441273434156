import { Injectable } from "@angular/core";


/**
 * Represents a key-value pair.
 */
export interface KeyValuePair<T> {
    key: string;
    value: T;
}

/**
 * Represents a widget cache model
 */
export interface WidgetCacheModel {
    reportComponentId: string;
    value: string;
}

@Injectable({
    providedIn: "root",
})
export class IndexedDBService {
    private dbName = "one-pact-indexeddb";
    private storeName = "KeyValueStore";
    private db!: IDBDatabase;
    private isDBInitialized = false;

    constructor() {
        this.initDB();
    }

    /**
     * Initializes the IndexedDB.
     */
    private initDB(): Promise<void> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, 1);

            request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                this.db = (event.target as IDBOpenDBRequest).result;
                if (!this.db.objectStoreNames.contains(this.storeName)) {
                    this.db.createObjectStore(this.storeName, { keyPath: "key" });
                }
            };

            request.onsuccess = (event: Event) => {
                this.db = (event.target as IDBOpenDBRequest).result;
                this.isDBInitialized = true; // DB hazır
                resolve();
            };

            request.onerror = (event: Event) => {
                console.error("IndexedDB initialization failed", (event.target as IDBOpenDBRequest).error);
                reject((event.target as IDBOpenDBRequest).error);
            };
        });
    }

    /**
     * Ensures DB initialization before performing operations.
     */
    private async ensureDBReady(): Promise<void> {
        if (!this.isDBInitialized) {
            await this.initDB();
        }
    }

    /**
     * Set a key-value pair in IndexedDB.
     */
    async setItem<T>(key: string, value: T): Promise<void> {
        await this.ensureDBReady();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(this.storeName, "readwrite");
            const store = transaction.objectStore(this.storeName);

            const request = store.put({ key, value: JSON.stringify(value) });

            request.onsuccess = () => resolve();
            request.onerror = () => reject(request.error);
        });
    }

    /**
     * Get a value by key from IndexedDB.
     */
    async getItem<T>(key: string): Promise<T | null> {
        await this.ensureDBReady();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(this.storeName, "readonly");
            const store = transaction.objectStore(this.storeName);

            const request = store.get(key);

            request.onsuccess = () => {
                const result = request.result;
                if (result === undefined) {
                    resolve(null);
                } else {
                    try {
                        resolve(JSON.parse(result.value));
                    } catch (e) {
                        console.warn(`Failed to parse data for key: ${key}`, e);
                        resolve(null);
                    }
                }
            };

            request.onerror = () => reject(request.error);
        });
    }

    /**
     * Remove an item by key from IndexedDB.
     */
    async removeItem(key: string): Promise<void> {
        await this.ensureDBReady();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(this.storeName, "readwrite");
            const store = transaction.objectStore(this.storeName);

            const request = store.delete(key);

            request.onsuccess = () => resolve();
            request.onerror = () => reject(request.error);
        });
    }

    /**
     * Clear all data from the IndexedDB store.
     */
    async clear(): Promise<void> {
        await this.ensureDBReady();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(this.storeName, "readwrite");
            const store = transaction.objectStore(this.storeName);

            const request = store.clear();

            request.onsuccess = () => resolve();
            request.onerror = () => reject(request.error);
        });
    }





    /**
     * Filter items containing a specific key.
     * @param searchKey - The string to search for in keys.
     */
    async filterItems<T>(searchKey: string): Promise<KeyValuePair<T>[]> {
        await this.ensureDBReady();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(this.storeName, "readonly");
            const store = transaction.objectStore(this.storeName);

            const request = store.openCursor();
            const results: KeyValuePair<T>[] = [];

            request.onsuccess = (event) => {
                const cursor = (event.target as IDBRequest).result;

                if (cursor) {
                    const { key, value } = cursor.value;
                    if (key.includes(searchKey)) {
                        try {
                            results.push({
                                key,
                                value: JSON.parse(value) as T,
                            });
                        } catch (e) {
                            console.warn(`Failed to parse value for key: ${key}`, e);
                        }
                    }
                    cursor.continue(); // Move to the next record
                } else {
                    // No more records
                    resolve(results);
                }
            };

            request.onerror = () => reject(request.error);
        });
    }


    /**
     * Filter items containing any key from the provided list.
     * @param searchKeys - The list of strings to search for in keys.
     */
    async filterItemsByKeys<T>(searchKeys: string[]): Promise<KeyValuePair<T>[]> {
        await this.ensureDBReady();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(this.storeName, "readonly");
            const store = transaction.objectStore(this.storeName);

            const request = store.openCursor();
            const results: KeyValuePair<T>[] = [];

            request.onsuccess = (event) => {
                const cursor = (event.target as IDBRequest).result;

                if (cursor) {
                    const { key, value } = cursor.value;

                    // Check if the key contains any of the searchKeys
                    if (searchKeys.some((searchKey) => key.includes(searchKey))) {
                        try {
                            results.push({
                                key,
                                value: JSON.parse(value) as T,
                            });
                        } catch (e) {
                            console.warn(`Failed to parse value for key: ${key}`, e);
                        }
                    }
                    cursor.continue(); // Move to the next record
                } else {
                    // No more records
                    resolve(results);
                }
            };

            request.onerror = () => reject(request.error);
        });
    }



}
